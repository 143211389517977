<template>
  <transition name="fade">
    <div>
      <v-dialog
        v-model="showDialog"
        persistent
        max-width="400"
        class="rounded-xl"
        scrollable
      >
        <v-card class="pb-5" rounded="lg">
          <v-card-title
            class="text-h6 teal--text text--darken-3 d-flex justify-space-between pt-1 pe-1 pb-1"
          >
            {{ $t("string.bookInfo") }}
            <v-spacer></v-spacer>
            <CloseBtn size="30" :callback="callbackClose" />
          </v-card-title>
          <v-divider></v-divider>

          <v-card-text class="mt-3">
            <div class="d-flex justify-center mb-4">
              <v-btn
                small
                depressed
                :color="lang == 'en' ? 'yellow' : 'gray lighten-3'"
                @click="lang = 'en'"
              >
                English
              </v-btn>
              <v-btn
                small
                depressed
                :color="lang == 'zh' ? 'yellow' : 'grey lighten-3'"
                class="mx-2"
                @click="lang = 'zh'"
              >
                中文
              </v-btn>
              <v-btn
                small
                depressed
                :color="lang == 'ms' ? 'yellow' : 'grey lighten-3'"
                @click="lang = 'ms'"
              >
                Bahasa Melayu
              </v-btn>
            </div>
            <div v-for="(item, i) in data" :key="i">
              <v-row>
                <v-col cols="4"> {{ item.title[lang] }} </v-col>
                <v-col cols="6" v-if="item.downloadable">
                  <v-btn
                    small
                    color="yellow"
                    link
                    :href="item.value[lang]"
                    target="blank"
                  >
                    {{ item.action[lang] }}</v-btn
                  >
                </v-col>
                <v-col cols="6" v-else> {{ item.value[lang] }} </v-col>
                <v-col cols="2">
                  <v-btn
                    small
                    icon
                    v-if="!item.downloadable"
                    @click="copyToClipboard(item.value[lang])"
                  >
                    <v-icon small>mdi-content-copy</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-divider class="my-2"></v-divider>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="snackbar" :timeout="snackTimeout" height="10" left>
        <div class="d-flex justify-space-between">
          {{ $t("action.copied") }}
          <v-icon small> mdi-check </v-icon>
        </div>
      </v-snackbar>
    </div>
  </transition>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
  },
  props: ["showDialog", "callbackClose", "data"],
  data: () => ({
    lang: "en",
    snackbar: false,
    snackTimeout: 2000,
  }),
  computed: mapState({
    settings: (state) => state.settings.data,
  }),
  mounted() {
    this.lang = this.$_getLocale();
  },
  methods: {
    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
      this.snackbar = true;
    },
  },
};
</script>
